<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />
      <h1 class="text-xl text-left font-extrabold ml-4 mt-3">Back Pay</h1>
      <div class="w-7/12 mt-4 ml-8">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <div class="px-3">
        <card class="p-5 mt-6">
          <div class="p-3" style="border: 1px dashed #878e99">
            <p class="font-bold text-lg mb-4">Pay Run Information</p>
            <div class="flex">
              <card class="p-3 w-52">
                <h6 class="uppercase mb-2 font-bold text-sm text-flame">
                  Pay run Type
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{ payrunType }}
                </h6>
              </card>
              <card class="p-3 ml-2">
                <h6 class="uppercase font-bold text-sm text-flame">
                  Pay Cycle Details
                </h6>
                <div class="flex">
                  <div class="flex">
                    <h6 class="font-semibold mt-2 text-sm text-jet uppercase">
                      Pay Cycle:
                    </h6>
                    <span
                      class="px-2 py-1 ml-2 mt-1 mr-4 text-blueCrayola capitalize font-semibold text-sm"
                      style="
                        background: rgba(33, 118, 255, 0.15);
                        border-radius: 5px;
                      "
                    >
                      {{ payrunData.payCycle }}
                    </span>
                  </div>

                  <div class="flex">
                    <h6 class="font-semibold mt-2 text-sm text-jet uppercase">
                      Payment frequency:
                    </h6>
                    <span
                      class="px-2 py-1 ml-4 mr-4 text-blueCrayola capitalize font-semibold text-sm"
                      style="
                        background: rgba(33, 118, 255, 0.15);
                        border-radius: 5px;
                      "
                    >
                      {{
                        payFrequency === "twice_monthly"
                          ? "Twice Monthly"
                          : payFrequency === "weekly"
                          ? "Four Times Monthly"
                          : payFrequency
                      }}
                    </span>
                  </div>
                  <div class="flex">
                    <h6 class="font-semibold text-sm mt-2 text-jet uppercase">
                      Payment Schedule:
                    </h6>
                    <span
                      class="px-2 py-1 mx-3 text-blueCrayola capitalize font-semibold text-sm"
                      style="
                        background: rgba(33, 118, 255, 0.15);
                        border-radius: 5px;
                      "
                    >
                      {{ `${$getOrdinal(splitPosition)} Payment` }}
                    </span>
                  </div>
                </div>
              </card>
            </div>
          </div>
          <div class="flex justify-between my-5">
            <div class="flex flex-1">
              <card class="p-3 ml-2 w-40">
                <h6 class="font-black text-xs text-romanSilver uppercase">
                  Total Employees
                </h6>
                <h4 class="font-bold text-lg text-jet">
                  {{ totalEmployees }}
                </h4>
              </card>
              <card class="p-3 ml-2" style="min-width: 160px">
                <h6 class="font-black text-xs text-romanSilver uppercase">
                  Net Total Pay
                </h6>
                <h4 class="font-bold text-lg text-jet">
                  {{ convertToCurrency(totalNetPay) }}
                </h4>
              </card>
            </div>
            <div class="flex my-auto">
              <Button
                background-color="none"
                class="text-white mr-5 rounded"
                :background="appearance.buttonColor || defaultBTNColor"
                border="1px solid #F15A29"
                @click="save('Under Review')"
                :disabled="checkboxes.length === 0"
              >
                Submit Payrun
              </Button>
              <Button
                class="bg-white mr-3 text-dynamicBackBtn border border-solid border-dynamicBackBtn"
                style-name="width:inherit"
                @click="save('Drafts')"
                :disabled="checkboxes.length === 0"
              >
                Submit as Draft
              </Button>
            </div>
          </div>
          <card class="p-4 mt-6">
            <card-footer
              reloadcard
              printcard
              @reload="reload()"
              @sortType="handleSort($event)"
              @option="handleOptionSelect($event)"
              @searchResult="handleSearch($event)"
            >
            </card-footer>
          </card>
        </card>
      </div>
      <div>
        <div class="px-3"></div>
        <div class="px-3">
          <card class="p-1 mt-6 mb-8">
            <sTable
              :headers="headers"
              :items="filteredTableData"
              style="width: 100%"
              class="w-full"
              :has-checkbox="true"
              aria-label="payRun table"
              :has-number="false"
              :loading="tableLoading"
              @rowSelected="checkboxes = $event"
              :pagination-list="metaData"
              @page="handlePage($event)"
              page-sync
              @itemsPerPage="handleItemsPerPage($event)"
            >
              <template v-slot:item="{ item }">
                <div v-if="item.photo" class="flex items-center gap-3">
                  <div v-if="isPresent" class="rounded-full sideDot1"></div>
                  <div v-else class="rounded-full sideDot2"></div>
                  <img
                    class=""
                    :src="item.data.photo"
                    v-if="item.data.photo"
                    alt="photo"
                    style="height: 30px; width: 30px; border-radius: 5px"
                  />
                  <div
                    style="height: 35px; width: 35px; border-radius: 5px"
                    class="text-blueCrayola border text-center font-semibold pt-2"
                    v-else
                  >
                    {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
                  </div>
                </div>
                <div v-else-if="item.fname">
                  <span class="tw-font-bold" style="color: #525252"
                    >{{ item.data.fname }} {{ item.data.lname }}</span
                  >
                  <p class="uppercase text-xs text-romanSilver">
                    {{
                      item.data.orgFunction ? item.data.orgFunction.name : ""
                    }}
                  </p>
                </div>
                <span v-else-if="item.regularEarnings" class="tw-text-center">
                  {{
                    !item.data.regularEarnings
                      ? "Nil"
                      : convertToCurrency(item.data.regularEarnings)
                  }}
                </span>
                <span v-else-if="item.additions" class="flex text-center">
                  <Menu
                    right
                    top="-300"
                    margin="46"
                    class="p-0"
                    v-if="item.data.additions"
                  >
                    <template v-slot:title>
                      <div class="underline text-blueCrayola">
                        {{ convertToCurrency(item.data.additions) }}
                      </div>
                    </template>
                    <div style="width: 250px; padding: 10px 5px">
                      <div
                        class="flex flex-col py-2 px-3"
                        v-for="(additions, index) in item.data
                          .monthlyAdditionsPayItems"
                        :key="index"
                      >
                        <p class="pt-1 flex flex-grow text-sm font-semibold">
                          {{ additions.name }}
                        </p>
                        <p class="pt-1 flex text-xs text-romanSilver">
                          {{ convertToCurrency(additions.amount) }}
                        </p>
                      </div>
                    </div>
                  </Menu>
                  <span v-else>
                    {{ convertToCurrency(0) }}
                  </span>
                  <span>
                    <Icon
                      class-name="text-flame cursor-pointer ml-4 mt-0 self-center"
                      size="xms"
                      @click.native="handleClick('addition', item.data.userId)"
                      icon-name="addition"
                    />
                  </span>
                  <span v-if="item.data.additions > 1">
                    <Icon
                      class-name="text-blueCrayola cursor-pointer ml-2 mt-0 self-center"
                      size="xms"
                      @click.native="handleEdit('addition', item.data.userId)"
                      icon-name="edit"
                    />
                  </span>
                </span>
                <span v-else-if="item.deductions" class="flex text-center">
                  <Menu
                    right
                    top="-300"
                    margin="46"
                    class="p-0"
                    v-if="item.data.deductions"
                  >
                    <template v-slot:title>
                      <div class="underline text-desire">
                        ({{ convertToCurrency(item.data.deductions) }})
                      </div>
                    </template>
                    <div style="width: 250px; padding: 10px 5px">
                      <div
                        class="flex flex-col py-2 px-3"
                        v-for="(deduction, index) in item.data
                          .monthlyDeductionsPayItems"
                        :key="index"
                      >
                        <p class="pt-1 flex flex-grow text-sm font-semibold">
                          {{ deduction.name }}
                        </p>
                        <p class="pt-1 flex text-xs text-romanSilver">
                          {{ convertToCurrency(deduction.amount) }}
                        </p>
                      </div>
                    </div>
                  </Menu>
                  <span v-else>
                    {{ convertToCurrency(0) }}
                  </span>
                  <span>
                    <Icon
                      class-name="text-flame cursor-pointer ml-4 self-center"
                      size="xms"
                      @click.native="handleClick('deduction', item.data.userId)"
                      icon-name="addition"
                    />
                  </span>
                  <span v-if="item.data.deductions > 1">
                    <Icon
                      class-name="text-blueCrayola cursor-pointer ml-2 mt-0 self-center"
                      size="xms"
                      @click.native="handleEdit('deduction', item.data.userId)"
                      icon-name="edit"
                    />
                  </span>
                </span>
                <span v-else-if="item.paye" class="tw-text-center">
                  {{ item.data.paye ? convertToCurrency(item.data.paye) : 0 }}
                </span>
                <span v-else-if="item.employeePension" class="tw-text-center">
                  {{
                    item.data.employeePension
                      ? convertToCurrency(item.data.employeePension)
                      : 0
                  }}
                </span>
                <span v-else-if="item.nhfAmount" class="tw-text-center">
                  {{
                    item.data.nhfAmount
                      ? convertToCurrency(item.data.nhfAmount)
                      : 0
                  }}
                </span>
                <span v-else-if="item.reliefAllowance" class="tw-ml-6">
                  <c-text
                    class="tw--mt-6"
                    style="width: 131px"
                    type="number"
                    placeholder="Enter amount"
                    min="0"
                    @input="
                      checkReimbursement({
                        id: item.data.id,
                        value: item.data.reimbursements,
                      })
                    "
                    v-model="item.data.reimbursements"
                  />
                </span>
                <span v-else-if="item.netPay" class="grosspay">
                  {{ convertToCurrency(item.data.netPay) }}
                </span>
              </template>
            </sTable>
          </card>
        </div>
      </div>
    </div>
    <ModalBar
      v-if="setModal"
      @submit="submit"
      @close="resetModal()"
      submit="Apply"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <div class="w-full flex">
          <div class="text-darkPurple flex-grow">Filters</div>
          <div class="flex">
            <Icon
              class-name="text-darkPurple cursor-pointer mx-auto self-center"
              size="xs"
              icon-name="undo"
            />
          </div>
        </div>
      </template>
      <div class="w-full flex flex-col">
        <SearchInput
          placeholder="First, Last Name or Email"
          v-model="handleSearch"
          class="search"
          style="width: 100%"
        />
      </div>
      <div
        class="w-full mt-4 border border-dashed py-2 px-3"
        style="border-color: #878e99"
      >
        <div class="mt-1 w-full flex">
          <p
            class="uppercase text-romanSilver font-semibold"
            style="font-size: 10px"
          >
            Filter By :
          </p>
          <p class="font-semibold text-sm text-flame -mt-1 ml-1">
            Pay Run Type
          </p>
        </div>
        <div class="w-full border border-solid mt-2" />
        <div class="w-full mt-1 flex flex-col">
          <radio-button
            :options="categoryArray"
            col-span="col-span-12"
            class="m-4 text-darkPurple"
            row-gap="gap-y-2"
            v-model="payrunType"
            @change="resetRunby(payrunType)"
          />
        </div>
      </div>
      <div
        class="w-full mt-4 border border-dashed py-2 px-3"
        style="border-color: #878e99"
      >
        <div class="mt-1 w-full flex">
          <p
            class="uppercase text-romanSilver font-semibold"
            style="font-size: 10px"
          >
            Filter By :
          </p>
          <p class="font-semibold text-sm text-flame -mt-1 ml-1">Location(s)</p>
        </div>
        <div class="w-full border border-solid mt-2" />
        <div class="w-full mt-1 flex flex-col">
          <c-select
            placeholder="--Select--"
            :options="locationArray"
            variant="w-full"
            class="mt-3 w-1/2"
          />
        </div>
      </div>
      <div
        class="w-full mt-4 border border-dashed py-2 px-3"
        style="border-color: #878e99"
      >
        <div class="mt-1 w-full flex">
          <p
            class="uppercase text-romanSilver font-semibold"
            style="font-size: 10px"
          >
            Filter By :
          </p>
          <p class="font-semibold text-sm text-flame -mt-1 ml-1">Level(s)</p>
        </div>
        <div class="w-full border border-solid mt-2" style="height: 1px" />
        <div class="w-full mt-1 flex flex-col">
          <c-select
            placeholder="--Select--"
            :options="levelArray"
            variant="w-full"
            class="mt-3 w-1/2"
          />
        </div>
      </div>
      <div
        class="w-full mt-4 border border-dashed py-2 px-3"
        style="border-color: #878e99"
      >
        <div class="mt-1 w-full flex">
          <p
            class="uppercase text-romanSilver font-semibold"
            style="font-size: 10px"
          >
            Filter By :
          </p>
          <p class="font-semibold text-sm text-flame -mt-1 ml-1">Function(s)</p>
        </div>
        <div class="w-full border border-solid mt-2" />
        <div class="w-full mt-1 flex flex-col">
          <c-select
            placeholder="--Select--"
            :options="functionArray"
            variant="w-full"
            class="mt-3 w-1/2"
          />
        </div>
      </div>
      <div
        class="w-full mt-4 border border-dashed py-2 px-3"
        style="border-color: #878e99"
      >
        <div class="mt-1 w-full flex">
          <p
            class="uppercase text-romanSilver font-semibold"
            style="font-size: 10px"
          >
            Filter By :
          </p>
          <p class="font-semibold text-sm text-flame -mt-1 ml-1">
            Designation(s)
          </p>
        </div>
        <div class="w-full border border-solid mt-2" />
        <div class="w-full mt-1 flex flex-col">
          <c-select
            placeholder="--Select--"
            :options="employmentType"
            variant="w-full"
            class="mt-3 w-1/2"
          />
        </div>
      </div>
    </ModalBar>
    <side-modal
      :open-modal="openModal"
      :modal-type="modalType"
      :user-id="userId"
      @close="handleClose"
    />
    <edit-side-modal
      :open-modal="openEditModal"
      :modal-type="modalType"
      :user-id="userId"
      @close="handleEditClose"
    />
  </div>
</template>

<script>
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
import { mapState } from "vuex";
import lodash from "lodash/cloneDeep";
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import Button from "@/components/Button";
import Menu from "@/components/Menu";
import CardFooter from "@/components/CardFooter";
import RadioButton from "@/components/RadioButton";
import ModalBar from "@/components/ModalBar";

export default {
  components: {
    BackButton,
    Card,
    Breadcrumb,
    CSelect,
    CardFooter,
    SearchInput,
    CText,
    Menu,
    RadioButton,
    Button,
    STable,
    ModalBar,
    SideModal: () => import("../SideModal"),
    EditSideModal: () => import("../EditSideModal"),
  },
  data() {
    return {
      processAllEmployees: false,
      disableBtn: false,
        metaData: {},
        payrunData: {
          payCycle: ''
        },
      itemsPerPage: 50,
      numberOfPage: 1,
      tableLoading: false,
      breadcrumbs: [
        { disabled: false, text: "Payroll", href: "Payroll", id: "Payroll" },
        { disabled: false, text: "Payrun", href: "Payrun", id: "Payrun" },
        {
          disabled: false,
          text: "Off-Cycle Pay",
          href: "Payrun",
          id: "Off-Cycle",
        },
        { disabled: false, text: "Back Pay", href: "Payrun", id: "BackPay" },
      ],
      hierarchy: [],
      openModal: false,
      openEditModal: false,
      modalType: "",
      userId: "",
      isPresent: true,
      payrunType: "",
      selectedCategory: "",
      categoryArray: [
        {
          name: "Regular",
          value: "Regular",
          radioName: "job",
        },
        {
          name: "Off Cycle",
          value: "Off Cycle",
          radioName: "job",
        },
        {
          name: "Terminal Pay",
          value: "Terminal Pay",
          radioName: "job",
        },
      ],
      additionalItems: [],
      month: [],
      year: [],
      employeeAddition: [],
      amount: "",
      payCycleMonth: "",
      payCycleYear: "",
      reimbursement: "",
      variableMonth: "",
      employeeSearch: false,
      advancedSearch: false,
      netPay: [],
      payType: "",
      payFrequency: "",
      splitPosition: "",
      totalEmployees: "",
      totalNetPay: "",
      optimaUsers: [],
      paycycleSummary: [],
      employees: {},
      radioValue: "",
      allButton: "all",
      checkboxes: [],
      selected: [],
      allOptimaUsers: "",
      AVMonth: "",
      loading: true,
      tableData: [],
      item: [],
      setModal: false,
      headers: [
        { title: "", value: "photo" },
        { title: "Employee Name", value: "fname" },
        { title: "Regular Earnings", value: "regularEarnings" },
        { title: "Additions", value: "additions" },
        { title: "Deductions", value: "deductions" },
        { title: "PAYE", value: "paye" },
        { title: "Pension", value: "employeePension" },
        { title: "NHF", value: "nhfAmount" },
        { title: "Net Pay", value: "netPay", width: 10 },
      ],
      page: 1,
      pageCount: 0,
      runByRadioButton: [
        { name: "All", id: "all" },
        { name: "Location", id: "location" },
        { name: "Function", id: "function" },
        { name: "Employment Type", id: "employmentType" },
        { name: "Level", id: "level" },
      ],
      employmentType: [
        { name: "Full-time", id: "Full-time" },
        { name: "Part-time", id: "Part-time" },
        { name: "Intern", id: "Intern" },
        { name: "Company Temp", id: "Company-paid Temp" },
        { name: "Contractor", id: "Independent Contractor" },
        { name: "Agency Temp", id: "Agency-paid Temp" },
        { name: "Vendor Employee", id: "Vendor Employee" },
        { name: "Volunteer", id: "Volunteer" },
      ],
      runByCheckbox: [],

      paymentType: [
        { name: "Regular Pay", id: "regularPay" },
        { name: "13 Month", id: "13thMonth" },
      ],
      selectedPaymentType: "13thMonth",
      drawerVisible: false,
      selitems: [],
      locationArray: [],
      functionArray: [],
      employmentArray: [],
      levelArray: [],
      secondaryCategory: "",
      secondaryCategoryArray: "",
      employeeSearchInput:''
    };
  },

  watch: {
    selectedCategory(value) {
      if (value === "all") {
        this.secondaryCategory = false;
      } else if (value === "location") {
        this.secondaryCategory = "Location(s)";
        this.secondaryCategoryArray = this.locationArray;
      } else if (value === "function") {
        this.secondaryCategory = "Function(s)";
        this.secondaryCategoryArray = this.functionArray;
      } else if (value === "employmentType") {
        this.secondaryCategory = "Employment Type(s)";
        this.secondaryCategoryArray = this.employmentType;
      } else if (value === "level") {
        this.secondaryCategory = "Level(s)";
        this.secondaryCategoryArray = this.levelArray;
      }
    },
  },

  methods: {
    handleSearch(value) {
      this.employeeSearchInput = value;
    },
    handleClose() {
      this.getEmployees();
      this.openModal = false;
      this.modalType = "";
    },
    handleEditClose() {
      this.getEmployees();
      this.openEditModal = false;
      this.modalType = "";
    },
    handleEdit(type, id) {
      this.modalType = type;
      this.userId = id;
      this.openEditModal = true;
    },
    handleClick(type, id) {
      this.modalType = type;
      this.userId = id;
      this.openModal = true;
    },
    handleProcessAllEmployees() {},
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.getEmployees();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.getEmployees();
    },
    buttonOptionSelected(option) {
      if (option === "Save as Draft") {
        this.save("Drafts");
      } else {
        this.$router.push({ name: "BackPay" });
      }
    },
    handleModal() {
      this.setModal = true;
    },
    invalid_permission() {
      this.$store.commit("SET_TOAST", {
        text: "You Do not have the required permission",
        color: "error",
      });
    },

    save(value) {
      this.disableBtn = true;
      if (this.checkboxes.length) {
        const getPayrun = {
          employeeIds: [],
        };
        getPayrun.createdBy = this.$AuthUser.id;
        getPayrun.orgId = this.$AuthUser.orgId;
        getPayrun.payType = this.payType;
        getPayrun.payFrequency = this.payFrequency;
        getPayrun.splitPosition = this.splitPosition;
        getPayrun.month = this.$route.query.month;
        getPayrun.year = this.$route.query.year;
        getPayrun.paymentType = this.payrunType;
        getPayrun.type = 'backpay';

        this.checkboxes.map((element) => {
          if (element.reimbursements) {
            parseFloat(element.reimbursements);
          }
          getPayrun.employeeIds.push(element.employeeId);
        });

        if (value === "Drafts") {
          getPayrun.payrollStatus = "draft";
        } else {
          getPayrun.payrollStatus = "review";
        }

        this.$_getPayrunData(getPayrun)
          .then((result) => {
            this.disableBtn = false;
            this.$toasted.success("Pay run created successfully", {
              duration: 5000,
            });
            this.$router.push({name : "Pay Runs", query: { currentTab: value }});
            return result;
          })
          .catch((err) => {
            this.disableBtn = false;
            this.$toasted.error(
              "An error occured, Please contact an IT personnel",
              { duration: 5000 }
            );
            return err;
          });
      } else {
        this.$toasted.error(
          "No employee selected, Please select an employee to pay",
          { duration: 3000 }
        );
        this.disableBtn = false;
      }
    },
    checkReimbursement(value) {
      const { id } = value;
      let amount = parseFloat(value.value);
      const hasNetPay = this.netPay.find((element) => element.id === id);
      if (hasNetPay) {
        const data = this.tableData.find((item) => item.id === id);
        if (!amount) amount = 0;
        const initialNetPayObject = lodash(hasNetPay);

        initialNetPayObject.netPay += amount;
        data.netPay = initialNetPayObject.netPay;
      }
    },
    activateSelection(value, date) {
      const ObjDate = JSON.parse(date);

      this.payCycleMonth = ObjDate.month;
      this.payCycleYear = ObjDate.year;
      this.employees = {};
      if (value === "Regular") {
        this.loading = true;
        this.getEmployees(
          `employees/?type=${value}&month=${ObjDate.month}&year=${ObjDate.year}`
        );
      } else if (value === "Cycle") {
        this.loading = true;
        if (this.selectedPaymentType === "regularPay") {
          this.getEmployees(
            `employees/?paymentType=${this.selectedPaymentType}&month=${ObjDate.month}&year=${ObjDate.year}`
          );
        } else {
          this.getEmployees(
            `employees/?paymentType=${this.selectedPaymentType}`
          );
        }
      }
    },

    makeSearch(value) {
      if (this.payrunType !== "Terminal") {
        this.item.map((employee) => {
          if (employee.id === value) {
            this.tableData.push(employee);
          }
        });
      } else {
        this.employees = this.item.find((employee) => employee.id === value);
      }
    },
    resetRunby(value) {
      this.employees = {};
      console.log(value);
      if (value === "Regular") {
        console.log(1);
        this.loading = true;
        this.getEmployees(
          `employees/?type=${value}&month=${this.payCycleMonth}&year=${this.payCycleYear}`
        );
      } else if (value === "Off Cycle") {
        console.log(2);
        this.loading = true;
        console.log(this.selectedPaymentType);
        if (this.selectedPaymentType === "regularPay") {
          this.getEmployees(
            `employees/?paymentType=${this.selectedPaymentType}&month=${this.payCycleMonth}&year=${this.payCycleYear}`
          );
        } else {
          console.log(3);
          this.getEmployees(
            `employees/?paymentType=${this.selectedPaymentType}`
          );
        }
      }
    },
    checkButtonChange(value) {
      // this.loadingRunBy = true;
      // this.loading = true;
      this.selected = [];
      this.radioValue = value;
      this.employeeSearch = false;
      if (value !== "employee") {
        this.getEmployees(
          `employees/?type=${value}&month=${this.payCycleMonth}&year=${this.payCycleYear}`
        );
      }

      // const allSelected = { name: "All", id: "0" };
      if (value === "all") {
        this.runByCheckbox = [];
        this.loadingRunBy = false;
      }
      if (value === "location") {
        this.runByCheckbox = this.locationArray;
      }
      if (value === "function") {
        this.runByCheckbox = this.functionArray;
      }
      if (value === "employmentType") {
        this.runByCheckbox = this.employmentType;
        // this.runByCheckbox.unshift(allSelected);
        this.loadingRunBy = false;
      }
      if (value === "level") {
        this.runByCheckbox = this.levelArray;
      }
      if (value === "employee") {
        this.tableData = [];
        this.employeeSearch = true;
        this.loadingRunBy = false;
        this.loading = false;
      }
    },
    reload() {
      this.getEmployees();
    },
    getEmployees() {
      this.tableLoading = true;
      const { month, year, ...payload } = this.$route.query;

      const monthArr = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      this.payrunData.payCycle = `${monthArr[month - 1]} ${year}`;

      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${Number(this.metaData.page)}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      const payType = payload.payType ? `payType=${payload.payType}` : "";
      const payFrequency = payload.payFrequency
        ? `&payFrequency=${payload.payFrequency}`
        : "";
      const designationId = payload.designationId
        ? `&designationId=${payload.designationId}`
        : "";
      const locationId = payload.locationId
        ? `&locationId=${payload.locationId}`
        : "";
      const functionId = payload.functionId
        ? `&functionId=${payload.functionId}`
        : "";
      const levelId = payload.levelId ? `&levelId=${payload.levelId}` : "";
      const employeeId = payload.employeeId
        ? `&employeeId=${payload.employeeId}`
        : "";
      const splitPosition = payload.splitPosition
        ? `&splitPosition=${payload.splitPosition}`
        : ``;
      const employmentType = payload.employmentType
        ? `&employmentType=${payload.employmentType}`
        : ``;
      const formatPayload = `${payType}${payFrequency}${designationId}${locationId}${functionId}${levelId}${employeeId}${splitPosition}${pageNumber}${itemPage}${employmentType}`;
      this.$_getEmployeePayrun({ month, year }, formatPayload)
        .then((response) => {
          const {
            payType: pay,
            payFrequency: frequency,
            splitPosition: split,
            totalEmployees,
            totalNetPay,
            type,
          } = response.data;
          this.payType = pay;
          this.metaData = response.data.meta;
          this.payFrequency = frequency;
          this.splitPosition = split;
          this.totalEmployees = totalEmployees;
          this.totalNetPay = totalNetPay;
            this.payrunType = type;
          this.employeeAddition = response.data.employees;
          this.netPay = lodash(response.data.employees);
          this.tableData = response.data.employees;
          this.metaData = response.data.meta;
          if (this.tableData.length === 0) {
            this.$router.push({ name: "Pay Runs" }).then(() => {
              this.$toasted.error(
                "No employee(s) found with such Pay frequency",
                { duration: 9000 }
              );
              this.tableLoading = false;
            });
          } else {
            this.additionalItems =
              response.data.employees.monthlyAdditionsPayItems;
            this.item = response.data.employees.map((item) => ({
              name: `${item.fname} ${item.lname}`,
              ...item,
            }));
            this.loading = false;
            this.tableLoading = false;
          }
        })
        .catch((error) => {
          this.$toasted.error(
            `${error.message} please contact your administrator`,
            { duration: 5000 }
          );
        });
    },
    getOnePayrun() {
      this.$_getOnePayrun(this.$route.params.id).then((result) => {
        this.netPay = lodash(result.data.payrun.employees);
        this.tableData = result.data.payrun.employees;
        this.payCycleMonth = result.data.payrun.month;
        this.isPresent = true;
        this.loading = false;
      });
    },
    resetModal() {
      this.setModal = false;
    },
  },
  computed: {
    filteredTableData()  {
      if(!this.employeeSearchInput ){
        return this.tableData
      }
        const filteredData = this.tableData.filter(item => item.fname.toLowerCase().includes(this.employeeSearchInput.toLowerCase()) || item.lname.toLowerCase().includes(this.employeeSearchInput.toLowerCase()) || item.orgFunction?.name.toLowerCase().includes(this.employeeSearchInput.toLowerCase()));
      return filteredData

    },
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
  beforeMount() {
    this.getEmployees();
  },
};
</script>

<style scoped>
.buttons {
  width: 126px;
  height: 44px;
  border-radius: 5px;
}
.sideDot1 {
  width: 7px;
  height: 7px;
  background-color: #46aa80;
}
.sideDot2 {
  width: 7px;
  height: 7px;
  background-color: #c3c2c4;
}
.grosspay {
  background: #2176ff26;
  padding: 0.3rem 0.7rem;
  color: #2176ff;
  border-radius: 5px;
}
</style>
